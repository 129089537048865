import { withWidth } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiPaper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import { isWidthUp } from "@material-ui/core/withWidth";
import { spacing } from "@material-ui/system";
import React, { useState, useDebugValue } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const drawerWidth = 250;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { children, routes, width } = props;

  useDebugValue(mobileOpen ? "mobile Open" : "mobile Close");

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      {/* Permanent menu for large screen */}
      <Hidden smDown>
        <Drawer /* This is just a div */>
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            open={mobileOpen}
            variant="permanent"
            onClose={() => setMobileOpen(!mobileOpen)}
          />
        </Drawer>
      </Hidden>

      {/* Menu closed by default but can be open on mobile */}
      <Hidden mdUp>
        <Drawer /* This is just a div */>
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            open={mobileOpen}
            variant="temporary"
            onClose={() => setMobileOpen(!mobileOpen)}
          />
        </Drawer>
      </Hidden>

      <AppContent>
        <Header onDrawerToggle={() => setMobileOpen(!mobileOpen)} />
        <MainContent p={isWidthUp("lg", width) ? 10 : 5}>{children}</MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default withWidth()(Dashboard);
