import React, { useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { useAuth0 } from "../auth/auth0";
import DashboardLayout from "../layouts/Dashboard";
import GenericLayout from "../layouts/Generic";
import Page404 from "../pages/errors/Page404";
import history from "./history";
import { reports as reportsRoutes } from "./index";

// todo: This way of securing a route(s) starts to paint before the re-render triggers to perform the redirect. Look into this?
const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    // todo: Setting appState for the return URI (redirected to log in and then should return to the URI) doesn't trigger correct Route. Need to fix
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props) => (isAuthenticated === true ? <Component {...props} /> : null);

  return <Route {...rest} path={path} render={render} />;
};

const privateChildRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }) =>
    children ? (
      // Route item with children
      children.map(({ childPath, component: ChildComponent }) => (
        <PrivateRoute
          key={childPath}
          path={childPath}
          exact
          component={(props) => (
            <Layout>
              <ChildComponent {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <PrivateRoute
        key={path}
        path={path}
        exact
        component={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

// Not in use atm

// const childRoutes = (Layout, routes) =>
//   routes.map(({ children, path, component: Component }) =>
//     children ? (
//       // Route item with children
//       children.map(({ childPath, component: ChildComponent }) => (
//         <Route
//           key={childPath}
//           path={childPath}
//           exact
//           render={(props) => (
//             <Layout>
//               <ChildComponent {...props} />
//             </Layout>
//           )}
//         />
//       ))
//     ) : (
//       // Route item without children
//       <Route
//         key={path}
//         path={path}
//         exact
//         render={(props) => (
//           <Layout>
//             <Component {...props} />
//           </Layout>
//         )}
//       />
//     )
//   );

const Routes = () => {
  const { user } = useAuth0();

  return (
    <Router history={history}>
      <Switch>
        {user && !user.isSuperAdmin && !user.isServiceAgent && !user.isInsightCustomer && (
          <GenericLayout>
            <Page404 />
          </GenericLayout>
        )}
        {privateChildRoutes(DashboardLayout, reportsRoutes)},
        <Route
          render={() => (
            <GenericLayout>
              <Page404 />
            </GenericLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
