import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;

  max-width: 1024px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

function Generic({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

export default Generic;
