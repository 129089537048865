import createAuth0Client from "@auth0/auth0-spa-js";
import React, { useContext, useEffect, useState } from "react";
import { setErrorUser } from "../pages/utils/errorHandler";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const scope = [
  "read:machine",
  "read:packHouse",
  "read:serviceProvider",
  "read:statistic",
  "read:diagnostic",
  "read:notification",
  "read:user",
  "create:machine",
  "create:packHouse",
  "create:serviceProvider",
  "create:statistic",
  "create:diagnostic",
  "create:notification",
  "create:user",
  "modify:machine",
  "modify:packHouse",
  "modify:serviceProvider",
  "modify:statistic",
  "modify:diagnostic",
  "modify:notification",
  "modify:user",
]
  .join(" ")
  .trim();

export const auth0ClientPromise = createAuth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENTID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope,
  redirect_uri: window.location.origin,
});

const roleUrl = process.env.REACT_APP_AUTH0_ROLEURL;
const SuperAdminRole = "Super Admin";
const ServiceAgentRole = "Service Agent";
const InsightCustomerRole = "Customer Insight:Basic";

const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  authClientPromise,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await authClientPromise;
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthed = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthed);

      if (isAuthed) {
        const authedUser = await auth0FromHook.getUser();
        authedUser.isSuperAdmin = authedUser[roleUrl].includes(SuperAdminRole);
        authedUser.isServiceAgent = authedUser[roleUrl].includes(ServiceAgentRole);
        authedUser.isInsightCustomer = authedUser[roleUrl].includes(InsightCustomerRole);
        setUser(authedUser);
        setErrorUser(authedUser);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const authedUser = await auth0Client.getUser();
    authedUser.isSuperAdmin = authedUser[roleUrl].includes(SuperAdminRole);
    authedUser.isServiceAgent = authedUser[roleUrl].includes(ServiceAgentRole);
    authedUser.isInsightCustomer = authedUser[roleUrl].includes(InsightCustomerRole);
    setUser(authedUser);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const authedUser = await auth0Client.getUser();
    authedUser.isSuperAdmin = authedUser[roleUrl].includes(SuperAdminRole);
    authedUser.isServiceAgent = authedUser[roleUrl].includes(ServiceAgentRole);
    authedUser.isInsightCustomer = authedUser[roleUrl].includes(InsightCustomerRole);
    setLoading(false);
    setIsAuthenticated(true);
    setUser(authedUser);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...props) => auth0Client.getIdTokenClaims(...props),
        loginWithRedirect: (...props) => auth0Client.loginWithRedirect(...props),
        getTokenSilently: (...props) => auth0Client.getTokenSilently(...props),
        getTokenWithPopup: (...props) => auth0Client.getTokenWithPopup(...props),
        getUserData: (...props) => auth0Client.getTokenWithPopup(...props),
        logout: (...props) => auth0Client.logout(...props),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
export default Auth0Provider;
