import { createTheme } from "@material-ui/core/styles";
import breakpoints from "./breakpoints";
import overrides from "./overrides";
import props from "./props";
import shadows from "./shadows";
import typography from "./typography";
import variant from "./variants";

const theme = (themeVariant) => {
  return createTheme(
    {
      spacing: 4,
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      body: themeVariant.body,
      report: themeVariant.report,
      header: themeVariant.header,
      palette: themeVariant.palette,
      sidebar: themeVariant.sidebar,
    },
    themeVariant.name
  );
};

// only supporting 1 variant for now. Extend here if we want to support multiple

export default theme(variant);
