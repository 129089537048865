import React from "react";
import styled from "styled-components";

import Helmet from "react-helmet";

import MuiButton from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: 10px;
  text-align: center;
  background: transparent;
`;

const Page500 = (props) => {
  const { error, componentStack, resetError } = props;

  return (
    <Wrapper>
      <Helmet title={`500 Error | ${error.message}`} />
      <img
        src={`${process.env.PUBLIC_URL}/static/img/injured-robot.png`}
        alt="injured"
        style={{ maxWidth: "100%" }}
      />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        500
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Error: {error.message || "Internal Server Error"}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The error has been reported
      </Typography>

      <Box m={10}>
        <div>{componentStack}</div>
      </Box>
      <Box align="center">
        <Button onClick={resetError} variant="contained" color="secondary" mt={2}>
          Try Again
        </Button>
      </Box>
    </Wrapper>
  );
};

export default Page500;
