import MuiAppBar from "@material-ui/core/AppBar";
import MuiIconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import { AccountBox as AccountIcon, ExitToApp } from "@material-ui/icons";
import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import { useAuth0 } from "../auth/auth0";
import history from "../routes/history";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  color: ${(props) => props.theme.header.color};
  svg {
    width: 22px;
    height: 22px;
  }
`;

const MenuDesktop = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
  }
`;

const MenuMobile = styled.div`
  display: flex;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`;

const AvatarIcon = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(6)}px`};
  height: ${({ theme }) => `${theme.spacing(6)}px`};
`;

const Header = (props) => {
  const { onDrawerToggle } = props;

  const { logout, user } = useAuth0();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // Maybe we do something else with this power button? Unsure, but can update later.
  // For now, it's a 'power' button to logout

  const handleAccountButtonClick = (event) => {
    setProfileAnchor(event.currentTarget);
  };

  const handleAccountButtonClose = () => {
    setProfileAnchor(null);
  };
  const handleLogout = () => {
    const returnTo = `${window.location.protocol}//${window.location.host}`;
    logout({ returnTo });
  };
  const openProfile = () => {
    history.push("/account/profile");
  };
  const handleDrawerToggle = () => {
    onDrawerToggle();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "account-menu";
  const renderAccountMenu = (
    <Menu
      anchorEl={profileAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(profileAnchor)}
      onClose={handleAccountButtonClose}
    >
      {/* <MenuItem onClick={openProfile}>Profile</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={openProfile}>
        <IconButton color="inherit">
          <AccountIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton color="inherit">
          <ExitToApp />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <div style={{ flexGrow: 1 }} />
          <MenuDesktop>
            <IconButton
              color="inherit"
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={handleAccountButtonClick}
            >
              {user && <AvatarIcon alt={user.name} src={user.picture} />}
            </IconButton>
          </MenuDesktop>
          <MenuMobile>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </MenuMobile>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderAccountMenu}
    </>
  );
};

export default withTheme(Header);
