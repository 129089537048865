import firebase from "firebase/app";
import "firebase/analytics";
import { ApolloProvider } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import React from "react";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";
import { SnackbarProvider } from "notistack";
import client from "./apollo/client";
import Auth0Provider, { auth0ClientPromise } from "./auth/auth0";
import history from "./routes/history";
import Routes from "./routes/Routes";
import maTheme from "./theme";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

if (process.env.NODE_ENV === "production") {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const App = () => (
  <Auth0Provider authClientPromise={auth0ClientPromise} onRedirectCallback={onRedirectCallback}>
    <ApolloProvider client={client}>
      <Helmet titleTemplate="%s | Aporo Customer" defaultTitle="Aporo Customer" />
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={maTheme}>
          <ThemeProvider theme={maTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Routes />
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  </Auth0Provider>
);

export default App;
