import { InMemoryCache } from "@apollo/client";

/**
 * Note: the cache is not persistent. It is a "session" cache
 */
const cache = new InMemoryCache({
  typePolicies: {
    LiveStatistic: {
      keyFields: ["packerId"],
    },
  },
});

export default cache;
