import React, { useEffect, useState, Suspense } from "react";
import Loader from "./Loader";

// const sleep = (m) => new Promise((r) => setTimeout(r, m));

export default (importComponent) => {
  function AsyncComponent(props) {
    const [component, setComponent] = useState(null);

    useEffect(() => {
      const getComponent = async () => {
        const { default: impComponent } = await importComponent();
        setComponent(impComponent);
      };
      getComponent();
      return () => {
        setComponent(null);
      };
    }, []);

    const C = component;
    return C ? (
      <Suspense fallback={<Loader />}>
        <C {...props} />
      </Suspense>
    ) : (
      <Loader />
    );
  }
  return AsyncComponent;
};
