import MuiBox from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import MuiDrawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import MuiList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { darken, rgba } from "polished";
import React, { useLayoutEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "../auth/auth0";
import routes from "../routes/index";
import "../vendor/perfect-scrollbar.css";

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;
const AporoLogo = styled.img`
  margin: ${(props) => props.theme.spacing(1)}px;
  height: auto;
`;

const BrandChip = styled(Chip)`
  background-color: ${(props) => props.theme.sidebar.header.brand.color};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 60%;
  height: 20px;
  margin-left: 2px;
  margin-bottom: 1px;
  padding: 4px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.5)}px;
    padding-right: ${(props) => props.theme.spacing(1.5)}px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(14)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) =>
    props.label === "new"
      ? props.theme.sidebar.badge.backgroundNew
      : props.theme.sidebar.badge.backgroundDefault};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  isSuperAdmin,
  badge,
  ...rest
}) {
  const { user } = useAuth0();

  // TMP to hide superadmin category
  if (isSuperAdmin && user && user.isSuperAdmin !== true) {
    return <></>;
  }

  const CategoryIconComp = isOpen ? <CategoryIconMore /> : <CategoryIconLess />;
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? CategoryIconComp : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}

function SidebarLink({ name, to, badge, isSuperAdmin }) {
  const { user } = useAuth0();

  // TMP to hide superadmin category
  if (isSuperAdmin && user && user.isSuperAdmin !== true) {
    return <></>;
  }

  return (
    <Link button dense component={NavLink} exact to={to} activeClassName="active">
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
}

const Sidebar = (props) => {
  const { open, classes, staticContext, variant, location, ...other } = props;
  const { user } = useAuth0();
  const [t] = useTranslation();
  const [elements, setElements] = useState({});

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(elements).forEach(
      (item) =>
        elements[index] ||
        setElements(() => ({
          [item]: false,
        }))
    );

    // Toggle selected element
    setElements((elems) => ({
      [index]: !elems[index],
    }));
  };

  useLayoutEffect(() => {
    const pathName = location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = !!(route.containsHome && pathName === "/");

      setElements(() => ({
        [index]: isActive || isOpen || isHome,
      }));
    });
  }, [location.pathname]);

  return (
    <Drawer variant={variant} open={open} {...other}>
      <Brand>
        <AporoLogo alt="Aporo" src="/static/img/logo-large.svg" style={{ width: "143px" }} />
        <Box ml={1}>
          <BrandChip label="Insight" />
        </Box>
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category, index) => (
              <React.Fragment key={category.id}>
                {category.header &&
                (!category.isSuperAdmin || (user && user.isSuperAdmin === true)) ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children ? (
                  <React.Fragment key={`sd-${category.id}`}>
                    <SidebarCategory
                      isOpen={!elements[index]}
                      isCollapsable
                      name={t(category.id)}
                      icon={category.icon}
                      isSuperAdmin={category.isSuperAdmin}
                      button
                      onClick={() => toggle(index)}
                    />

                    <Collapse in={elements[index]} timeout="auto" unmountOnExit>
                      {category.children.map((route) => (
                        <SidebarLink
                          key={route.name}
                          name={t(route.name)}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                          isSuperAdmin={route.isSuperAdmin}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  <SidebarCategory
                    isCollapsable={false}
                    name={t(category.id)}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    isSuperAdmin={category.isSuperAdmin}
                    exact
                    badge={category.badge}
                  />
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2}>
          {/* <Grid item>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              variant="dot"
            >
              <Avatar alt="Matt Demler" src="/static/img/something.jpg" />
            </StyledBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">Matt Demler</SidebarFooterText>
            <SidebarFooterSubText variant="caption">
              Robotics Plus Ltd
            </SidebarFooterSubText>
          </Grid> */}
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);
