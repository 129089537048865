import StackdriverErrorReporter from "stackdriver-errors-js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { name, version } from "../../../package.json";

const environment = process.env.NODE_ENV;

// eslint-disable-next-line import/no-mutable-exports
let errorHandler = { report: console.error };

/**
 * Initialize Stackdriver Error Reporter only if api key exists
 * Error must be reported manually
 */
function initStackdriverErrorReporter() {
  if (process.env.REACT_APP_STACKDRIVER_API_KEY && process.env.REACT_APP_GCP_PROJECTID) {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
      key: process.env.REACT_APP_STACKDRIVER_API_KEY,
      projectId: process.env.REACT_APP_GCP_PROJECTID || "",
      version,
      service: "webapp",
    });
  }
}

/**
 * Initialize Sentry (reports to sentry.io)
 * Catch any uncaught exception
 */
function initSentry() {
  // Note: if we need to ignore errors: https://github.com/guardian/dotcom-rendering/blob/master/src/web/browser/sentry/sentry.tsx

  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT || "production",
      release: `${name}@${version}`,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.25,
    });
  }
}

/**
 * Initialize Sentry and Stackdriver
 */
export function init() {
  if (environment === "production") {
    initStackdriverErrorReporter();
    initSentry();
  }
}

/**
 * Set user's uid within error reporting context (can be one or
 * many error handling utilities)
 * @param {Object} user - Auth0 user data
 */
export function setErrorUser(user) {
  if (user?.email) {
    // Set user within Stackdriver
    if (errorHandler?.setUser) {
      errorHandler.setUser(user.email);
    }
    // Set user within Sentry
    Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  }
}

export default errorHandler;
