import { Layers } from "@material-ui/icons";
import React from "react";
import async from "../components/Async";

const Reports = async(() => import("../pages/Reports"));
const Report = async(() => import("../pages/Report"));
const Settings = async(() => import("../pages/Settings"));

export const reportsRoute = {
  id: "Reports",
  name: "Reports",
  path: "/",
  icon: <Layers />,
  component: Reports,
};

export const reportRoute = {
  id: "Report",
  path: "/report/:reportId",
  icon: <Layers />,
  component: Report,
};

// todo: temporary removal of settings route unitl menu items are fixed

export const settingsRoute = {
  id: "Settings",
  name: "Settings",
  path: "/settings",
  icon: <Layers />,
  component: Settings,
};

export const reports = [reportsRoute, reportRoute, settingsRoute];
export default [reportsRoute, settingsRoute];
